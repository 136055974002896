<template>
  <div className="flex flex-col min-h-screen overflow-hidden">
    <!-- Site header -->
    <Header />

    <!-- Button to scroll to top -->
    <div>
      <img
        v-if="showBackToTop"
        class="fixed bottom-0 right-0 mb-8 mr-8 cursor-pointer back-to-top-button"
        src="@/images/chevron-up-1.png"
        @click="scrollToTop"
      />
    </div>

    <!-- Page content -->
    <Hero />
    <About />
    <Features />
    <Pricing />
    <Faqs />
    <Cta />

    <!-- Site footer -->
    <Footer />
  </div>
</template>

<script>
import Header from '../partials/Header.vue';
import Hero from '../partials/Hero.vue';
import About from '../partials/About.vue';
import Features from '../partials/Features.vue';
import Pricing from '../partials/Pricing.vue';
import Cta from '../partials/Cta.vue';
import Footer from '../partials/Footer.vue';
import Faqs from '../partials/Faqs.vue';

export default {
  name: 'Landing',
  components: {
    Header,
    Hero,
    About,
    Features,
    Pricing,
    Faqs,
    Cta,
    Footer,
  },
  data: () => ({
    showBackToTop: false,
  }),
  methods: {
    handleScroll() {
      this.scrolledSoFar = window.pageYOffset;
      if (window.pageYOffset > 200) {
        this.showBackToTop = true;
      } else {
        this.showBackToTop = false;
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.back-to-top-button {
  width: 50px;
  height: 40px;
  z-index: 1000;
  @media screen and (max-width: 640px) {
    width: 2rem;
    height: 1.5rem;
  }
}
</style>
