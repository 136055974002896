import { createApp } from 'vue';
import router from './router';
import App from './App.vue';
import 'aos/dist/aos.css';
import './css/style.css';
const VueScrollTo = require('vue-scrollto');

const app = createApp(App);
app.use(router);

app.use(VueScrollTo, {
  container: 'body',
  duration: 700,
  easing: 'ease-in-out',
  offset: 0,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});
app.mount('#app');
