<template>
  <section id="faq">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="py-12 md:py-20">
        <!-- Section header -->
        <div class="pb-12 md:pb-20 flex justify-center align-items-center">
          <h2 class="h2 font-hk-grotesk">FAQ</h2>
        </div>

        <!-- Columns -->
        <div class="md:flex md:space-x-12 space-y-8 md:space-y-0">
          <!-- Column -->
          <div class="w-full md:w-1/2 space-y-8">
            <!-- Item -->
            <div class="space-y-2">
              <p class="text-xl font-hk-grotesk font-medium">
                Do You Provide Data for Crypto ?
              </p>
              <p class="text-slate-400 text-justify">
                Currently we only provide data for 14 Forex Pairs, Gold and
                Silver USD Pairs. We are working on adding additional markets
                later on.
              </p>
            </div>

            <!-- Item -->
            <div class="space-y-2">
              <p class="text-xl font-hk-grotesk font-medium">
                Do LiquiCharts Indicators repaint ?
              </p>
              <p class="text-slate-400 text-justify">
                No, LiquiCharts Indicators do not repaint. They are not based on
                the price, but on external data that we collect and calculate
                from the market.
              </p>
            </div>

            <!-- Item -->
            <div class="space-y-2">
              <p class="text-xl font-hk-grotesk font-medium">
                Can I get a discount ?
              </p>
              <p class="text-slate-400 text-justify">
                We know that not everyone can afford our services, specially in
                countries with high inflation. If you think you qualify for a
                discount, contact us at support@liquicharts.com and we will be
                glad to help you.
              </p>
            </div>
          </div>

          <!-- Column -->
          <div class="w-full md:w-1/2 space-y-8">
            <!-- Item -->
            <div class="space-y-2">
              <p class="text-xl font-hk-grotesk font-medium">
                Do I need coding knowledge to use this platform ?
              </p>
              <p class="text-slate-400 text-justify">
                No, you don't need any coding knowledge to use LiquiCharts. You
                can use it with just a few clicks.
              </p>
            </div>

            <!-- Item -->
            <div class="space-y-2">
              <p class="text-xl font-hk-grotesk font-medium">
                Is there a way to become an Affiliate reseller ?
              </p>
              <p class="text-slate-400 text-justify">
                Currently our Affiliate reseller program is on hold. Once
                resumed, you will be able to earn a percentage of the revenue
                generated by your referrals.
              </p>
            </div>

            <!-- Item -->
            <div class="space-y-2">
              <p class="text-xl font-hk-grotesk font-medium">
                What forms of payment do you accept ?
              </p>
              <p class="text-slate-400 text-justify">
                Currently we only accept cryptocurrency payments, including
                Bitcoin, Ethereum, Tether (USDT) and others. We are in the
                process of adding additional payment methods.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Faqs',
};
</script>
