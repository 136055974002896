<template>
  <section
    id="features"
    class="relative"
  >
    <!-- Bg gradient -->
    <div
      class="absolute top-0 left-0 right-0 bg-gradient-to-b from-gray-800 to-gray-900 opacity-60 h-[10rem] pointer-events-none -z-10"
      aria-hidden="true"
    ></div>

    <!-- Bg illustration -->
    <div
      class="absolute top-0 left-1/2 -z-10 ml-[390px]"
      aria-hidden="true"
    >
      <img
        src="../images/features-illustration.svg"
        class="max-w-none"
        width="608"
        height="305"
        alt="Illustration"
      />
    </div>

    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="py-4 md:py-10">
        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center pb-2 md:pb-6">
          <h2 class="h2 font-uncut-sans">Our Top Features</h2>
          <div class="max-w-2xl mx-auto">
            <p class="text-xl text-gray-400"></p>
          </div>
        </div>
        <!-- First Row -->
        <div class="items-start mt-2">
          <!-- Main item -->
          <div
            class="flex flex-col"
            data-aos="zoom-out"
          >
            <!-- Text -->
            <p class="text-2xl font-bold text-gray-200 text-center">
              4 Liquidity Indicators
            </p>
            <p class="text-lg text-gray-200 mb-2 text-center">
              (More Coming Soon)
            </p>
            <div
              class="grid md:grid-cols-2 grid-cols-1 justify-items-center gap-10"
            >
              <div
                class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
              >
                <div class="flex justify-center items-center">
                  <svg
                    class="mb-3 w-3 h-3 fill-current text-emerald-500"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"
                    />
                  </svg>
                </div>
                <h5
                  class="text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center"
                >
                  Liquidity Levels Indicator
                </h5>
                <p
                  class="font-normal text-gray-700 dark:text-gray-400 text-center"
                >
                  Displays The Most Liquid Levels/Points. Used As Potential
                  Price Targets & Reversal Points.
                </p>
              </div>

              <div
                class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
              >
                <div class="flex justify-center items-center">
                  <svg
                    class="mb-3 w-3 h-3 fill-current text-emerald-500"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"
                    />
                  </svg>
                </div>
                <h5
                  class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center"
                >
                  Liquidity Volume Indicator
                </h5>
                <p
                  class="font-normal text-gray-700 dark:text-gray-400 text-center"
                >
                  Displays The Liquidity Volume Percentage Above and Below The
                  Current Price.
                </p>
              </div>

              <div
                class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
              >
                <div class="flex justify-center items-center">
                  <svg
                    class="mb-3 w-3 h-3 fill-current text-emerald-500"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"
                    />
                  </svg>
                </div>
                <h5
                  class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center"
                >
                  Liquidity Moving Average
                </h5>
                <p
                  class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center"
                >
                  (LMA Experimental)
                </p>
                <p
                  class="font-normal text-gray-700 dark:text-gray-400 text-center"
                >
                  Displays The Average Liquidity Level Momentum. Used as Trend
                  Indicator.
                </p>
              </div>

              <div
                class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
              >
                <div class="flex justify-center items-center">
                  <svg
                    class="mb-3 w-3 h-3 fill-current text-emerald-500"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"
                    />
                  </svg>
                </div>
                <h5
                  class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center"
                >
                  Liquidity Support/Resistance
                </h5>
                <p
                  class="font-normal text-gray-700 dark:text-gray-400 text-center"
                >
                  Displays The Liquidity Boundaries. Used as Support/Resistance
                  Indicator.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- End First Row -->
        <!-- Second Row  -->
        <div
          class="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none mt-12"
        >
          <!-- 1st item -->
          <div
            class="flex flex-col items-center"
            data-aos="zoom-out"
          >
            <!-- Icon -->
            <div class="mb-4">
              <svg
                width="56"
                height="56"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <defs>
                  <radialGradient
                    cx="50%"
                    cy="89.845%"
                    fx="50%"
                    fy="89.845%"
                    r="89.85%"
                    id="icon1-b"
                  >
                    <stop
                      stop-color="#3B82F6"
                      stop-opacity=".64"
                      offset="0%"
                    />
                    <stop
                      stop-color="#F472B6"
                      stop-opacity=".876"
                      offset="100%"
                    />
                  </radialGradient>
                  <circle
                    id="icon1-a"
                    cx="28"
                    cy="28"
                    r="28"
                  />
                </defs>
                <g
                  fill="none"
                  fill-rule="evenodd"
                >
                  <use
                    fill="url(#icon1-b)"
                    xlink:href="#icon1-a"
                  />
                  <image
                    x="8"
                    y="8"
                    width="40"
                    height="40"
                    xlink:href="../images/features-pairs.png"
                  />
                </g>
              </svg>
            </div>
            <!-- Text -->
            <p class="text-2xl font-bold text-gray-200 text-center mb-2">
              16 Currency Pairs
            </p>
            <p class="text-lg text-gray-400 text-center">
              14 Forex Pairs (Majors & Crosses) with Gold and Silver USD pairs
            </p>
          </div>

          <!-- 2nd item -->
          <div
            class="flex flex-col items-center"
            data-aos="zoom-out"
            data-aos-delay="200"
          >
            <!-- Icon -->
            <div class="mb-4">
              <svg
                width="56"
                height="56"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <defs>
                  <radialGradient
                    cx="50%"
                    cy="89.845%"
                    fx="50%"
                    fy="89.845%"
                    r="89.85%"
                    id="icon2-b"
                  >
                    <stop
                      stop-color="#3B82F6"
                      stop-opacity=".64"
                      offset="0%"
                    />
                    <stop
                      stop-color="#F472B6"
                      stop-opacity=".876"
                      offset="100%"
                    />
                  </radialGradient>
                  <circle
                    id="icon2-a"
                    cx="28"
                    cy="28"
                    r="28"
                  />
                </defs>
                <g
                  fill="none"
                  fill-rule="evenodd"
                >
                  <use
                    fill="url(#icon2-b)"
                    xlink:href="#icon2-a"
                  />
                  <image
                    x="10"
                    y="9"
                    width="37"
                    height="37"
                    xlink:href="../images/features-chart.png"
                  />
                </g>
              </svg>
            </div>
            <!-- Text -->
            <p class="text-2xl font-bold text-gray-200 text-center mb-2">
              TradingView Charts
            </p>
            <p class="text-lg text-gray-400 text-center">
              Advanced Charting with 100+ Indicators powered by
              <a
                href="https://www.tradingview.com/"
                class="text-blue-500"
                target="_blank"
              >
                TradingView
              </a>
            </p>
          </div>

          <!-- 3rd item -->
          <div
            class="flex flex-col items-center"
            data-aos="zoom-out"
            data-aos-delay="400"
          >
            <!-- Icon -->
            <div class="mb-4">
              <svg
                width="56"
                height="56"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <defs>
                  <radialGradient
                    cx="50%"
                    cy="89.845%"
                    fx="50%"
                    fy="89.845%"
                    r="89.85%"
                    id="icon3-b"
                  >
                    <stop
                      stop-color="#3B82F6"
                      stop-opacity=".64"
                      offset="0%"
                    />
                    <stop
                      stop-color="#F472B6"
                      stop-opacity=".876"
                      offset="100%"
                    />
                  </radialGradient>
                  <circle
                    id="icon3-a"
                    cx="28"
                    cy="28"
                    r="28"
                  />
                </defs>
                <g
                  fill="none"
                  fill-rule="evenodd"
                >
                  <use
                    fill="url(#icon3-b)"
                    xlink:href="#icon3-a"
                  />
                  <image
                    x="5.5"
                    y="6"
                    width="45"
                    height="45"
                    xlink:href="../images/features-time.png"
                  />
                </g>
              </svg>
            </div>
            <!-- Text -->
            <p class="text-2xl font-bold text-gray-200 text-center mb-2">
              2 Years Of Data
            </p>
            <p class="text-lg text-gray-400 text-center">
              Access to indicators' historical data for up to 2 years ready for
              backtesting
            </p>
          </div>
        </div>
        <!-- End Second Row -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Features',
};
</script>
