<template>
  <footer>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <!-- Blocks -->
      <div class="grid sm:grid-cols-12 gap-8 py-8 md:py-12">
        <!-- 1st block -->
        <div class="sm:col-span-12 lg:col-span-4 lg:max-w-xs">
          <div class="mb-2">
            <div class="flex justify-start items-center">
              <!-- Logo -->
              <img
                class="w-8 h-8"
                src="../images/dark-logo.png"
                alt="LiquiCharts Logo"
              />
              <!-- Name -->
              <p class="text-lg lg:text-xl">LIQUICHARTS</p>
            </div>
          </div>
          <div class="text-sm text-gray-600">
            <a
              class="text-gray-400 hover:text-blue-500 transition duration-150 ease-in-out"
              href="#0"
              >Terms</a
            >
            ·
            <a
              class="text-gray-400 hover:text-blue-500 transition duration-150 ease-in-out"
              href="#0"
              >Privacy Policy</a
            >
          </div>
        </div>

        <!-- 2nd block -->
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <p class="text-xs text-gray-200 font-semibold uppercase mb-2">
            Product
          </p>
          <ul class="text-sm space-y-2">
            <li>
              <a
                class="text-gray-400 hover:text-gray-200 transition duration-150 ease-in-out cursor-pointer"
                v-scroll-to="'#about'"
                >About</a
              >
            </li>
            <li>
              <a
                class="text-gray-400 hover:text-gray-200 transition duration-150 ease-in-out cursor-pointer"
                v-scroll-to="'#features'"
                >Features</a
              >
            </li>
            <li>
              <a
                class="text-gray-400 hover:text-gray-200 transition duration-150 ease-in-out cursor-pointer"
                v-scroll-to="'#pricing'"
                >Pricing</a
              >
            </li>
            <li>
              <a
                class="text-gray-400 hover:text-gray-200 transition duration-150 ease-in-out cursor-pointer"
                v-scroll-to="'#faq'"
                >FAQ</a
              >
            </li>
          </ul>
        </div>

        <!-- 3rd block -->
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <p class="text-xs text-gray-200 font-semibold uppercase mb-2">
            Resources
          </p>
          <ul class="text-sm space-y-2">
            <li>
              <a
                class="text-gray-400 hover:text-blue-500 transition duration-150 ease-in-out"
                href="#"
                >Learn (Coming Soon)</a
              >
            </li>
            <li>
              <a
                class="text-gray-400 hover:text-blue-500 transition duration-150 ease-in-out"
                href="#"
                >Partners (Coming Soon)</a
              >
            </li>
          </ul>
        </div>

        <!-- 5th block -->
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <p class="text-xs text-gray-200 font-semibold uppercase mb-2">
            Company
          </p>
          <ul class="text-sm space-y-2">
            <li>
              <a
                class="text-gray-400 hover:text-blue-500 transition duration-150 ease-in-out"
                href="mailto:support@liquicharts.com"
                >Contact us: support@liquicharts.com</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>
