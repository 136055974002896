<template>
  <section
    id="hero"
    class="relative overflow-hidden h-auto xl:h-screen"
  >
    <!-- Bottom Bg gradient -->
    <div
      class="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-gray-800 to-gray-900 opacity-60 h-[10rem] pointer-events-none -z-10"
      aria-hidden="true"
    ></div>

    <!-- Background Illustration -->
    <div
      class="absolute left-1/2 -translate-x-1/2 pointer-events-none -z-10"
      aria-hidden="true"
    >
      <img
        src="../images/hero-illustration.svg"
        class="max-w-none"
        width="2143"
        height="737"
        alt="Hero Illustration"
      />
    </div>

    <!-- Hero content -->
    <div
      class="flex flex-col items-center text-center md:text-left md:flex-row align-items-center m-16 md:my-32 lg:mx-12 lg:m-24 xl:m-32"
    >
      <!-- Left Content -->
      <div class="left-item mt-12 md:w-1/2 p-2 lg:p-16 xl:p-16 2xl:p-16">
        <div data-aos="zoom-out"></div>
        <!-- LIQUICHARTS -->
        <p
          class="mb-6 text-xl font-bold text-white text-4xl lg:text-4xl xl:text-5xl 2xl:text-6xl"
          data-aos="zoom-out"
          data-aos-delay="100"
          style="max-width: 100%; width: 100%; line-height: 2.5rem"
        >
          LIQUICHARTS
          <!-- Leading Indicators -->
          <br />
          <span
            class="font-italic text-xl lg:text-2xl xl:text-2xl 2xl:text-4xl"
            data-aos="zoom-out"
            data-aos-delay="150"
            style="font-style: italic"
          >
            Leading Indicators
          </span>
        </p>

        <!-- Content Description -->
        <p
          class="text-base md:text-lg lg:text-xl xl:text-2xl text-white-400"
          data-aos="zoom-out"
          data-aos-delay="200"
        >
          Unlock Your Trading Potential with Our Advanced Market Data.
        </p>
        <p
          class="text-base md:text-lg lg:text-xl xl:text-2xl text-white-400 mt-2"
          data-aos="zoom-out"
          data-aos-delay="200"
        >
          Enhance Your Trading with Our Liquidity-Based Indicators.
        </p>

        <!-- Try it NOW Button -->
        <div
          class="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center md:justify-start space-y-4 sm:space-y-0 sm:space-x-4 mt-8"
          data-aos="zoom-out"
          data-aos-delay="300"
        >
          <div>
            <a
              class="btn text-white bg-gradient-to-t from-blue-600 to-blue-400 hover:to-blue-500 focus:border-2 focus:border-blue-600 focus:shadow-outline-blue w-full shadow-lg group"
              href="https://www.liquicharts.com/app"
            >
              Try it NOW
            </a>
          </div>
        </div>
        <p
          class="text-gray-400 mt-4 text-sm md:text-base lg:text-lg xl:text-xl"
          data-aos="zoom-out"
          data-aos-delay="350"
        >
          Sign up for a free 7-day trial of LiquiCharts, no credit card
          required.
        </p>
      </div>
      <!-- Right Content -->
      <div
        class="right-item sm:w-1/2 mt-32 lg:mt-32 xl:mt-44 relative mac"
        data-aos="zoom-out"
        data-aos-delay="300"
      >
        <img
          src="../images/hero-image.png"
          alt="Image"
        />

        <!-- logo icon -->
        <img
          class="screen-logo"
          src="../images/logo.png"
          alt="Logo Icon"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Hero',
};
</script>

<!-- media queries -->
<style lang="scss" scoped>
/* Relevant styles */

.mac {
  display: inline-block;
  position: relative;
  font-size: calc(0.52px + 0.13vw);
  height: 120em;
  width: 230em;
  margin-bottom: 28em;
  box-sizing: content-box;
  border-radius: 5.67em;
  padding: 9.5em 9.5em 8.84em 9.5em;
  box-shadow: inset 9.5em 0 #111, inset -9.5em 0 #111, inset 0 9.5em #111,
    inset 0 -8.84em #111;
  border-bottom: 21em solid transparent;
  perspective: 8.34em;
  -webkit-perspective: 8.34em;
}

.mac::before {
  content: '';
  position: absolute;
  top: calc(100% + 21em);
  left: calc(50% - 60em);
  width: 120em;
  height: 40em;
  background: radial-gradient(farthest-side at right top, #7e7e7f, transparent)
      bottom 3em left 13em,
    radial-gradient(farthest-side at left top, #7e7e7f, transparent) bottom 3em
      right 13em,
    radial-gradient(farthest-side at center bottom, #a1a1a3, transparent) bottom
      1em left 15em,
    radial-gradient(farthest-side at center bottom, #a1a1a3, transparent) bottom
      1em left 6em,
    radial-gradient(farthest-side at center bottom, #a1a1a3, transparent) bottom
      1em right 6em,
    linear-gradient(
        0deg,
        #e7e7e9 5%,
        #79797b 11%,
        #828280 17%,
        #9e9ea0 20%,
        #f1f1f1 30%,
        #eaeaea 40%,
        #77787c 100%
      )
      top left 17em,
    linear-gradient(#e7e7e9, #e7e7e9) bottom 1em left 14em,
    linear-gradient(-15deg, #e7e7e9 48%, transparent 52%) bottom 1em left,
    linear-gradient(15deg, #e7e7e9 48%, transparent 52%) bottom 1em right,
    linear-gradient(45deg, #9b9b9d 0%, #ececee 10%, #ececee 90%, #9b9b9d 100%)
      bottom left,
    radial-gradient(farthest-side at left top, transparent 48%, #7e7e7f 50%) top
      23em left 14em,
    radial-gradient(farthest-side at right top, transparent 48%, #7e7e7f 50%)
      top 23em right 14em;
  background-size: 4em 2em, 4em 2em, 90em 10em, 90em 4em, 90em 4em, 86em 39em,
    92em 4em, 14em 4em, 14em 4em, 100% 1em, 7em 24em, 7em 24em;
  border-bottom-left-radius: 23.34em 0.34em;
  border-bottom-right-radius: 23.34em 0.34em;
  background-repeat: no-repeat;
  transform-origin: top;
  transform: rotateX(3deg) scale3d(0.54, 0.4, 1);
  -webkit-transform-origin: top;
  -webkit-transform: rotateX(3deg) scale3d(0.54, 0.4, 1);
  box-shadow: 0 2.3em 1.34em -2em, 0 2em 1.34em -2em;
}

.mac::after {
  content: '';
  position: absolute;
  top: 139.4em;
  left: 0em;
  width: 100%;
  height: 14.5%;
  border-radius: 0 0 5.67em 5.67em;
  background: linear-gradient(45deg, #898a8c, #e1e2e4);
  background-position: center;
  background-repeat: no-repeat;
}

.screen-logo {
  position: absolute;
  top: 141em;
  left: 117em;
  width: 50px;
  height: 50px;
  z-index: 1;
  @media screen and (max-width: 1600px) {
    width: calc(10px + 2.5vw);
    height: calc(10px + 2.5vw);
    top: calc(110em + 5vw);
    left: calc(1rem + 18vw);
  }
  @media screen and (max-width: 1200px) {
    width: calc(10px + 2.5vw);
    height: calc(10px + 2.5vw);
    top: calc(118em + 4vw);
    left: calc(1rem + 19vw);
  }
  @media screen and (max-width: 767px) {
    width: calc(10px + 2.5vw);
    height: calc(10px + 2.5vw);
    top: calc(135em + 1vw);
    left: calc(93em + 5vw);
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mac::after {
    background: transparent;
    box-shadow: 1.67em -738.34em 8.34em -8.34em #111,
      1.67em -734.84em 0 -10em #474956, 1.67em -734.84em 0 -8.34em #1b1c26,
      1.67em -734.84em 0 -6.67em #36373a, inset 14.17em 11.34em #c5c6c8,
      inset -64em -64.17em 0 -80.5em #111, 30em 28em 0.67em #c5c6c8,
      13em 24em 0.67em #111, 13em 36.17em 0.67em #111, 12em 38.5em 0.67em #111,
      10.5em 41em 0.67em #111, 11em 46.67em 1.17em -5.17em #111,
      12.17em 47em 1.17em -5.17em #111, 13.34em 45.84em 1.17em -5.17em #111,
      6em 45.17em 1.17em -5.17em #111, 2.84em 44.84em 1.17em -5.17em #111,
      -1.5em 45.5em 1.17em -5.17em #111, -3.5em 46.17em 1.17em -5.17em #111,
      -6.17em 47.17em 1.17em -5.17em #111, -4em 33em 1.17em 3.84em #111,
      -4.17em 35.67em 1.17em 2.84em #111, -3.84em 40.34em 0.67em #111,
      -5.34em 39.67em 0.67em #111, -5.17em 42.5em 1.17em -1.34em #111,
      -5.67em 44.34em 1.17em -2.84em #111, 1.34em 42.67em 1.17em -2.84em #111,
      4.5em 42.67em 1.17em -2.84em #111, -9.84em 32.34em 1.17em -1.67em #111,
      -10.17em 30.17em 1.17em -1.67em #111, -10.17em 27.84em 1.17em -1.67em #111,
      -9.84em 25.84em 1.17em -1.67em #111, -9.17em 24em 1.17em -1.67em #111,
      -8.17em 22.67em 1.17em -1.67em #111, -6.67em 22.84em 1.17em -1.67em #111,
      -4.34em 23.5em 1.17em -1.67em #111, -1.34em 24.34em 1.17em -1.67em #111,
      7.17em 24em 1.17em -1.67em #111, 10.34em 23em 1.17em -1.67em #111,
      3em 24.67em 1.17em -1.67em #111;
  }
}

/* Irrelevant styles */
</style>
