<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <!-- CTA box -->
      <div
        class="relative bg-gradient-to-tr from-blue-600 to-purple-500 rounded py-10 px-8 md:py-16 md:px-12 overflow-hidden"
        data-aos="zoom-out"
      >
        <!-- Bg illustration -->
        <div
          class="hidden lg:block absolute right-0 top-1/2 -translate-y-1/2 mt-8 -z-10"
          aria-hidden="true"
        >
          <img
            src="../images/cta-illustration.svg"
            class="max-w-none"
            width="582"
            height="662"
            alt="Illustration"
          />
        </div>

        <div class="flex flex-col lg:flex-row justify-between items-center">
          <!-- CTA content -->
          <div class="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left">
            <p class="text-4xl font-bold font-uncut-sans mb-2">
              Try LiquiCharts Today for Free
            </p>
            <p class="text-blue-200">
              Sign up for a free 7-day trial of LiquiCharts, no credit card
              required.
            </p>
          </div>

          <!-- CTA button -->
          <div class="shrink-0">
            <a
              class="btn-sm text-white bg-gradient-to-t from-blue-600 to-blue-400 hover:to-blue-500 w-full group shadow-lg"
              href="https://www.liquicharts.com/app"
            >
              Start Free Trial
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Cta',
};
</script>
