<template>
  <section
    id="about"
    class="h-auto lg:h-screen"
  >
    <div class="max-w-6xl mx-auto px-4 sm:px-6 w-full">
      <div class="py-8 md:py-16 lg:py-16">
        <!-- Section header -->
        <div class="text-center pb-2 md:pb-2 md:mt-4">
          <h2
            class="h2 font-uncut-sans"
            data-aos="zoom-out"
          >
            Join The Winning Team
          </h2>
        </div>
        <!--  3 icons banks etc.  -->
        <div
          class="flex justify-evenly items-center mt-4 md:mt-12"
          data-aos="zoom-out"
        >
          <!-- Hedge Funds -->
          <div class="flex flex-col justify-center items-center">
            <svg
              class="about-icons-svg-side"
              width="150"
              height="150"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="-7 -7 70 70"
            >
              <defs>
                <radialGradient
                  cx="50%"
                  cy="89.845%"
                  fx="50%"
                  fy="89.845%"
                  r="89.85%"
                  id="icon1-b"
                >
                  <stop
                    stop-color="#3B82F6"
                    stop-opacity=".64"
                    offset="0%"
                  />
                  <stop
                    stop-color="#F472B6"
                    stop-opacity=".876"
                    offset="100%"
                  />
                </radialGradient>
              </defs>
              <g
                fill="none"
                fill-rule="evenodd"
              >
                <use
                  fill="url(#icon1-b)"
                  xlink:href="#icon1-a"
                />
                <image
                  x="8"
                  y="5"
                  width="40"
                  height="40"
                  xlink:href="../images/about-funds.png"
                />
              </g>
            </svg>
            <p class="text-base md:text-xl lg:text-4xl font-uncut-sans">
              Hedge Funds
            </p>
          </div>
          <!-- Big Banks -->
          <div class="flex flex-col justify-center items-center">
            <svg
              class="about-icons-svg-center"
              width="150"
              height="150"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="-7 -7 70 70"
            >
              <defs>
                <radialGradient
                  cx="50%"
                  cy="89.845%"
                  fx="50%"
                  fy="89.845%"
                  r="89.85%"
                  id="icon1-b"
                >
                  <stop
                    stop-color="#3B82F6"
                    stop-opacity=".64"
                    offset="0%"
                  />
                  <stop
                    stop-color="#F472B6"
                    stop-opacity=".876"
                    offset="100%"
                  />
                </radialGradient>
              </defs>
              <g
                fill="none"
                fill-rule="evenodd"
              >
                <use
                  fill="url(#icon1-b)"
                  xlink:href="#icon1-a"
                />
                <image
                  x="8"
                  y="5"
                  width="40"
                  height="40"
                  xlink:href="../images/about-banks.png"
                />
              </g>
            </svg>
            <p class="text-lg md:text-2xl lg:text-4xl font-uncut-sans">
              Big Banks
            </p>
          </div>
          <!-- Large Brokers -->
          <div class="flex flex-col justify-center items-center">
            <svg
              class="about-icons-svg-side"
              width="150"
              height="150"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="-7 -7 70 70"
            >
              <defs>
                <radialGradient
                  cx="50%"
                  cy="89.845%"
                  fx="50%"
                  fy="89.845%"
                  r="89.85%"
                  id="icon1-b"
                >
                  <stop
                    stop-color="#3B82F6"
                    stop-opacity=".64"
                    offset="0%"
                  />
                  <stop
                    stop-color="#F472B6"
                    stop-opacity=".876"
                    offset="100%"
                  />
                </radialGradient>
              </defs>
              <g
                fill="none"
                fill-rule="evenodd"
              >
                <use
                  fill="url(#icon1-b)"
                  xlink:href="#icon1-a"
                />
                <image
                  x="8"
                  y="8"
                  width="40"
                  height="40"
                  xlink:href="../images/about-brokers.png"
                />
              </g>
            </svg>
            <p class="text-base md:text-xl lg:text-4xl font-uncut-sans">
              Large Brokers
            </p>
          </div>
        </div>
      </div>
      <!-- Motive  +  Get Access Button -->
      <div
        class="flex flex-col justify-center text-center align-items-center text-center md:mt-2"
      >
        <p
          class="text-2xl md:text-4xl font-uncut-sans text-center"
          data-aos="zoom-out"
        >
          Join The
          <typewriter
            :words="[
              'Big Players',
              'Top Traders',
              'Market Makers',
              'Institutional Traders',
            ]"
          ></typewriter>
        </p>
        <p
          class="text-base md:text-lg lg:text-xl 2xl:text-2xl font-uncut-sans text-center mt-4 leading-6 md:leading-10 text-gray-400"
          data-aos="zoom-out"
        >
          Enhance Your Trading With Our Advanced Market Data.
        </p>
        <p
          class="text-base md:text-lg lg:text-xl 2xl:text-2xl font-uncut-sans text-center mt-4 leading-6 md:leading-10 text-gray-400"
          data-aos="zoom-out"
        >
          We Provide You With the Tools You Need to View the Market Like Big
          Players.
        </p>
        <p
          class="text-base md:text-lg lg:text-xl 2xl:text-2xl font-uncut-sans text-center mt-4 leading-6 md:leading-10 text-gray-400"
          data-aos="zoom-out"
        >
          Get Access To Our Data To Join the Ranks of Top Traders and Elevate
          Your Trading Game.
        </p>

        <div class="shrink-0">
          <a
            class="btn text-white bg-gradient-to-t from-blue-600 to-blue-400 hover:to-blue-500 group shadow-lg text-lg md:text-xl mt-8 mb-16 md:mt-12"
            href="https://www.liquicharts.com/app"
          >
            Join Now
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Typewriter from './Typewriter.vue';
export default {
  name: 'About',
  components: {
    Typewriter,
  },
};
</script>

<style lang="scss">
.about-icons-svg-side {
  width: 150px;
  height: 150px;
  @media screen and (max-width: 1200px) {
    width: 90px;
    height: 90px;
  }
  @media screen and (max-width: 500px) {
    width: 55px;
    height: 55px;
  }
}
.about-icons-svg-center {
  width: 150px;
  height: 150px;
  @media screen and (max-width: 1200px) {
    width: 115px;
    height: 115px;
  }
  @media screen and (max-width: 500px) {
    width: 85px;
    height: 85px;
  }
}
</style>
