<template>
  <header
    class="absolute w-full z-30"
    data-aos="fade-down"
    data-aos-delay="100"
    data-aos-duration="700"
  >
    <div class="mx-2 lg:mx-12 xl:mx-28 2xl:mx-32">
      <div class="flex items-center justify-between h-20">
        <!-- Site branding -->
        <div class="flex justify-center items-center">
          <!-- Logo -->
          <img
            class="w-8 h-8 md:w-12 md:h-12 lg:w-16 lg:h-16"
            src="../images/dark-logo.png"
            alt="LiquiCharts Logo"
          />
          <!-- Name -->
          <p class="text-2xl md:text-2xl lg:text-3xl">LIQUICHARTS</p>
        </div>

        <!-- Desktop navigation -->
        <nav class="hidden md:flex md:grow md:mr-8 lg:mr-28">
          <!-- Desktop menu links -->
          <ul class="flex grow justify-end flex-wrap items-center">
            <li>
              <a
                class="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out cursor-pointer header-nav-link"
                v-scroll-to="'#about'"
              >
                About
              </a>
            </li>
            <li>
              <a
                class="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out cursor-pointer header-nav-link"
                v-scroll-to="'#features'"
                >Features</a
              >
              <span
                class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-sky-600"
              ></span>
            </li>
            <li>
              <a
                class="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out cursor-pointer header-nav-link"
                v-scroll-to="'#pricing'"
                >Pricing</a
              >
            </li>
            <li>
              <a
                class="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out cursor-pointer header-nav-link"
                v-scroll-to="'#faq'"
                >FAQ</a
              >
            </li>
          </ul>

          <!-- Desktop sign in links -->

          <!-- Desktop sign in links -->
          <ul class="flex grow justify-end flex-wrap items-center">
            <li class="">
              <a
                class="btn-sm text-white bg-gradient-to-t from-blue-600 to-blue-400 hover:to-blue-500 w-full shadow-lg group"
                href="https://www.liquicharts.com/app"
              >
                Login / Register
              </a>
            </li>
          </ul>
        </nav>

        <!-- Mobile menu -->
        <div class="md:hidden mr-4">
          <!-- Hamburger button -->
          <button
            class="hamburger"
            ref="hamburger"
            :class="{ active: mobileNavOpen }"
            aria-controls="mobile-nav"
            :aria-expanded="mobileNavOpen"
            @click="mobileNavOpen = !mobileNavOpen"
          >
            <span class="sr-only">Menu</span>
            <svg
              class="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="4"
                width="24"
                height="2"
                rx="1"
              />
              <rect
                y="11"
                width="24"
                height="2"
                rx="1"
              />
              <rect
                y="18"
                width="24"
                height="2"
                rx="1"
              />
            </svg>
          </button>

          <!-- Mobile navigation -->
          <nav
            id="mobile-nav"
            ref="mobileNav"
            class="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out"
            :style="[
              mobileNavOpen
                ? {
                    maxHeight: $refs.mobileNav.scrollHeight + 28 + 'px',
                    opacity: 1,
                  }
                : { maxHeight: 0, opacity: 0.8 },
            ]"
          >
            <!-- flex column -->
            <ul
              class="bg-gray-800 px-4 py-2 flex flex-col"
              style="height: 17rem"
            >
              <li class="text-center mt-4">
                <a
                  class="text-stale-200 font-bold cursor-pointer"
                  v-scroll-to="'#about'"
                  @click="mobileNavOpen = !mobileNavOpen"
                  >About</a
                >
              </li>
              <li class="text-center mt-6">
                <a
                  class="text-stale-200 font-bold cursor-pointer"
                  v-scroll-to="'#features'"
                  @click="mobileNavOpen = !mobileNavOpen"
                  >Features</a
                >
              </li>
              <li class="text-center mt-6">
                <a
                  class="text-stale-200 font-bold cursor-pointer"
                  v-scroll-to="'#pricing'"
                  @click="mobileNavOpen = !mobileNavOpen"
                  >Pricing</a
                >
              </li>
              <li class="text-center mt-6">
                <a
                  class="text-stale-200 font-bold cursor-pointer"
                  v-scroll-to="'#faq'"
                  @click="mobileNavOpen = !mobileNavOpen"
                  >FAQ</a
                >
              </li>
              <li class="text-center mt-6">
                <a
                  class="btn-sm text-white bg-gradient-to-t from-blue-600 to-blue-400 hover:to-blue-500 shadow-lg group"
                  href="https://www.liquicharts.com/app"
                >
                  Login / Register
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',

  data: function () {
    return {
      mobileNavOpen: false,
    };
  },
  methods: {
    clickOutside(e) {
      if (
        !this.mobileNavOpen ||
        this.$refs.mobileNav.contains(e.target) ||
        this.$refs.hamburger.contains(e.target)
      )
        return;
      this.mobileNavOpen = false;
    },
    keyPress() {
      if (!this.mobileNavOpen || event.keyCode != 27) return;
      this.mobileNavOpen = false;
    },
  },
  mounted() {
    document.addEventListener('click', this.clickOutside);
    document.addEventListener('keydown', this.keyPress);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.clickOutside);
    document.removeEventListener('keydown', this.keyPress);
  },
};
</script>

<style lang="scss" scoped>
/* add an underline animation effect on header-nav-link */
.header-nav-link {
  position: relative;
  overflow: hidden;
  display: inline-block;
  transition: transform 0.1s ease-in-out;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 10%;
    width: 100%;
    height: 2px;
    background-color: #fff;
    transform: scaleX(0);
    /* transform origin the the center */
    transform-origin: center;
    transition: transform 0.3s ease-in-out;
  }
  &:hover {
    color: #fff;
  }
  &:hover::after {
    transform: scaleX(0.8);
  }
}
</style>
